<template>
  <section v-if="!carregarPagina">
    <div class="p-fluid formgrid grid" id="config_sistema">
      <pm-Panel header="Configuração Geral" class="col-md-12" :collapsed="collapse_geral" @click="mudarCollapse(0)">
        <div class="field col-12" >
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-3" >
              <label>Data de Início para Matricula Escolar: </label>
              <!--<pm-InputText v-model="configuracao.data_inicial_matricula"  type="date" placeholder="Início" />-->
              <pm-Calendar  v-model="configuracao.data_inicial_matricula" dateFormat="dd/mm/yy" :showIcon="true"  />
            </div>
            <div class="field col-12 md:col-3" >
              <label>Data de Fim para Matricula Escolar: </label>
              <!--<pm-InputText v-model="configuracao.data_final_matricula"  type="date" placeholder="Final" />-->
              <pm-Calendar  v-model="configuracao.data_final_matricula" dateFormat="dd/mm/yy" :showIcon="true"  />
            </div>
          </div>
        </div>

      </pm-Panel>

      <pm-Panel header="Educação Infantil"  class="col-md-12 mt-5" :collapsed="collapse_infantil" @click="mudarCollapse(1)">


        <div class="field col-12 md:col-8 lg:col-3">
          <label>Porcentagem de Falta:</label>
          <pm-InputNumber v-model="educ.porcentagem" mode="decimal"  showButtons :min="0" :max="100" />
        </div>
      </pm-Panel>


      <pm-Panel header="Ensino Fundamental I e Ensino Regular Noturno(ERN)" :collapsed="collapse_fund1" @click="mudarCollapse(2)" class="col-md-12 mt-5">
        <div class="row">
          <!--simulado-->
          <div class="field col-6">
            <div class="p-fluid formgrid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex " style=" padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Simulado?</label>
                    <div class="" v-for="n of fund1.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="fund1.simulado_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="fund1.simulado_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund1.simulado_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund1.simulado_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota do {{fund1.simulado_nome_exib}}:</label>
                  <pm-InputNumber   v-model="fund1.simulado" mode="decimal" showButtons :min="0" :max="100" />

                </div>
              </div>
            </div>
          </div>

          <!--atividades-->
          <div class="field col-6">
            <div class="p-fluid formgrid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex" style="padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Atividades Diversificadas?</label>
                    <div v-for="n of fund1.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="fund1.atividade_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="fund1.atividade_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund1.atividades_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund1.atividade_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota da {{fund1.atividades_nome_exib}}:</label>
                  <pm-InputNumber v-model="fund1.atividades" mode="decimal" showButtons :min="0" :max="100" />
                </div>

              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <!--avaliação-->
            <div class="field col-12">
              <div class="p-fluid formgrid grid">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">


                  <div class="field col-12 lg:col-12 mt-3" v-if="fund1.avaliacao_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund1.avaliacao_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund1.avaliacao_selec == 1" style="margin-bottom: -12px; padding-top: 20px;">
                  <label>Nota da {{fund1.avaliacao_nome_exib}}:</label>
                  <pm-InputNumber v-model="fund1.avaliacao" mode="decimal" showButtons :min="0" :max="100" />
                </div>
              </div>
            </div>

            <div class="col-12 row mt-5">
              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px;">
                <label>Média para ser promovido:</label>
                <pm-InputNumber v-model="fund1.media" mode="decimal" showButtons :min="0" :max="100" />
              </div>

              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px; padding-left: 0px;">
                <label>Porcentagem de Falta:</label>
                <pm-InputNumber v-model="fund1.porcentagem_falta" mode="decimal" showButtons :min="0" :max="100" />
              </div>
            </div>

          </div>

          <!--recuperação-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-4 md:col-4" style="padding: 0px; margin:0px">

                <div class="flex" style=" padding: 8px;">
                  <label style="">Possuirá Recuperação? </label>
                  <div style="margin:0px" v-for="n of fund1.tipos" :key="n.tera">
                    <pm-RadioButton :inputId="n.tera" name="n" :value="n.tera" v-model="fund1.recuperacao_selec"/>
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>

              </div>


              <div class="row" style="margin:0px">
                <div class="field col-12 md:col-8 lg:col-4" v-if="fund1.recuperacao_selec == '1'">
                  <label>A recuperação será?</label>
                  <pm-Dropdown v-if="fund1.recuperacao_bimestral == 1" v-model="fund1.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[1].nome"/>
                  <pm-Dropdown v-else-if="fund1.recuperacao_bimestral == 0" v-model="fund1.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[0].nome"/>
                  <pm-Dropdown v-else v-model="fund1.tipo_recuperacao" optionLabel="nome" :options="recuperacao" :placeholder="'Selecione uma opção'"/>
                </div>

                <div class="field col-12 md:col-7" v-if="fund1.tipo_recuperacao.nome != null && fund1.recuperacao_selec == '1'">

                  <label>Selecione o que a recuperação irá substitui! </label>
                  <pm-Dropdown v-model="fund1.aRecuperacaoSubstitui" optionLabel="nome" :options="recuperacaoSubstitui" placeholder="Selecione uma das opções" />
                </div>

              </div>


              <div class="field col-12" v-if="fund1.recuperacao_selec == true">
                <label>Peso das Recuperações:</label>
                <div class="p-fluid formgrid grid" v-if="fund1.tipo_recuperacao.nome == 'Bimestral'">
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund1.tera_primeiro_bimestre">
                    <label>1° Bimestre</label>
                    <pm-InputNumber v-model="fund1.recupercao_1b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund1.tera_segundo_bimestre">
                    <label>2° Bimestre</label>
                    <pm-InputNumber v-model="fund1.recupercao_2b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund1.tera_terceiro_bimestre">
                    <label>3° Bimestre</label>
                    <pm-InputNumber v-model="fund1.recupercao_3b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund1.tera_quarto_bimestre">
                    <label>4° Bimestre</label>
                    <pm-InputNumber v-model="fund1.recupercao_4b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" style="padding-top: 0px;" v-if="fund1.tera_recuperacao_final">
                    <label>Recuperação Final</label>
                    <pm-InputNumber v-model="fund1.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>
                <div class="p-fluid formgrid grid" v-if="fund1.tipo_recuperacao.nome == 'Semestral'">
                  <div class="field lg:col-2" v-if="fund1.tera_primeiro_semestre" style="padding-top: 0px;">
                    <label>1° Semestre</label>
                    <pm-InputNumber v-model="fund1.primeiro_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" v-if="fund1.tera_segundo_semestre" style="padding-top: 0px;">
                    <label>2° Semestre</label>
                    <pm-InputNumber v-model="fund1.segundo_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" v-if="fund1.tera_recuperacao_final"  style="padding-top: 0px;">
                    <label>Recuperação Final </label>
                    <pm-InputNumber v-model="fund1.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <somaNotas  :fund="fund1" />
      </pm-Panel>


      <pm-Panel header="Ensino Fundamental II"  class="col-md-12 mt-5" :collapsed="collapse_fund2" @click="mudarCollapse(3)">

        <div class="row">
          <!--simulado-->
          <div class="field col-6">
            <div class="p-fluid formgrid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex" style=" padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Simulado?</label>
                    <div v-for="n of fund2.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="fund2.simulado_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="fund2.simulado_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund2.simulado_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund2.simulado_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota do {{fund2.simulado_nome_exib}}:</label>
                  <pm-InputNumber   v-model="fund2.simulado" mode="decimal" showButtons :min="0" :max="100" />

                </div>
              </div>
            <!-- <div class="field col-12"></div> -->
            </div>
          </div>

          <!--atividades-->
          <div class="field col-6">
            <div class="p-fluid formgrid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex" style="padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Atividades Diversificadas?</label>
                    <div v-for="n of fund2.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="fund2.atividade_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="fund2.atividade_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund2.atividades_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund2.atividade_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota da {{fund2.atividades_nome_exib}}:</label>
                  <pm-InputNumber v-model="fund2.atividades" mode="decimal" showButtons :min="0" :max="100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <!--avaliação-->
            <div class="field col-12">
              <div class="p-fluid formgrid grid">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">
                  <div class="field col-12 lg:col-12 mt-3" v-if="fund2.avaliacao_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="fund2.avaliacao_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="fund2.avaliacao_selec == 1" style="margin-bottom: -12px; padding-top: 20px;">
                  <label>Nota da {{fund2.avaliacao_nome_exib}}:</label>
                  <pm-InputNumber v-model="fund2.avaliacao" mode="decimal" showButtons :min="0" :max="100" />
                </div>
                <div class="field col-12"></div>
              </div>
            </div>

            <div class="col-12 row mt-5">
              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px;">
                <label>Média para ser promovido:</label>
                <pm-InputNumber v-model="fund2.media" mode="decimal" showButtons :min="0" :max="100" />
              </div>

              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px; padding-left: 0px;">
                <label>Porcentagem de Falta:</label>
                <pm-InputNumber v-model="fund2.porcentagem_falta" mode="decimal" showButtons :min="0" :max="100" />
              </div>
            </div>

          </div>

          <!--recuperação-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-4 md:col-4" style="padding: 0px; margin:0px;">

                <div class="flex" style=" padding: 8px;">
                  <label >Possuirá Recuperação? </label>
                  <div style="margin:0px" v-for="n of fund2.tipos" :key="n.tera">
                    <pm-RadioButton :inputId="n.tera" name="n" :value="n.tera" v-model="fund2.recuperacao_selec"/>
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>

              </div>

              <div class="row" style="margin:0px">
                <div class="field col-12 md:col-8 lg:col-4" v-if="fund2.recuperacao_selec == '1'">
                  <label>A recuperação será?</label>
                  <pm-Dropdown v-if="fund2.recuperacao_bimestral == 1" v-model="fund2.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[1].nome"/>
                  <pm-Dropdown v-else-if="fund2.recuperacao_bimestral == 0" v-model="fund2.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[0].nome"/>
                  <pm-Dropdown v-else v-model="fund2.tipo_recuperacao" optionLabel="nome" :options="recuperacao" :placeholder="'Selecione uma opção'"/>
                </div>

                <div class="field col-12 md:col-8" v-if="fund2.tipo_recuperacao.nome != null && fund2.recuperacao_selec == '1'">
                  <label>Selecione o que a recuperação irá substitui!</label>
                  <pm-Dropdown v-model="fund2.aRecuperacaoSubstitui" optionLabel="nome" :options="recuperacaoSubstitui" placeholder="Selecione uma das opções" />
                </div>
              </div>

              <div class="field col-12" v-if="fund2.recuperacao_selec == true">
                <label>Peso das Recuperações:</label>
                <div class="p-fluid formgrid grid" v-if="fund2.tipo_recuperacao.nome == 'Bimestral'">
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund2.tera_primeiro_bimestre">
                    <label>1° Bimestre</label>
                    <pm-InputNumber v-model="fund2.recupercao_1b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund2.tera_segundo_bimestre">
                    <label>2° Bimestre</label>
                    <pm-InputNumber v-model="fund2.recupercao_2b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund2.tera_terceiro_bimestre">
                    <label>3° Bimestre</label>
                    <pm-InputNumber v-model="fund2.recupercao_3b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="fund2.tera_quarto_bimestre">
                    <label>4° Bimestre</label>
                    <pm-InputNumber v-model="fund2.recupercao_4b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" style="padding-top: 0px;" v-if="fund2.tera_recuperacao_final">
                    <label>Final</label>
                    <pm-InputNumber v-model="fund2.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>
                <div class="p-fluid formgrid grid" v-if="fund2.tipo_recuperacao.nome == 'Semestral'">
                  <div class="field lg:col-2" v-if="fund2.tera_primeiro_semestre" style="padding-top: 0px;">
                    <label>1° Semestre</label>
                    <pm-InputNumber v-model="fund2.primeiro_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" v-if="fund2.tera_segundo_semestre" style="padding-top: 0px;">
                    <label>2° Semestre</label>
                    <pm-InputNumber v-model="fund2.segundo_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" v-if="fund2.tera_recuperacao_final"  style="padding-top: 0px;">
                    <label>Recuperação Final</label>
                    <pm-InputNumber v-model="fund2.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <somaNotas  :fund="fund2" />

      </pm-Panel>
      <pm-Panel header="EJA (Primeiras Fases)" class="col-md-12 mt-5" :collapsed="collapse_eja2" @click="mudarCollapse(5)">
        <div class="row">
          <!--simulado-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex" style=" padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Simulado?</label> &nbsp;&nbsp;
                    <div v-for="n of eja2.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="eja2.simulado_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="eja2.simulado_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="eja2.simulado_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="eja2.simulado_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota do {{eja2.simulado_nome_exib}}:</label>
                  <pm-InputNumber   v-model="eja2.simulado" mode="decimal" showButtons :min="0" :max="100" />

                </div>
              </div>
            </div>
          </div>

          <!--atividades-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-12 md:col-6" style="padding-top: 15px;">

                <div class="flex" style="padding: 8px;">
                  <label style="margin-left: 0px;">Possuirá Atividades Diversificadas?</label>
                  <div v-for="n of eja2.tipos" :key="n.tera">
                    <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="eja2.atividade_selec"  />
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>

                <div class="field col-12 lg:col-12 mt-3" v-if="eja2.atividade_selec == 1">
                  <label >Nome Exibido:</label>
                  <pm-InputText v-model="eja2.atividades_nome_exib"  type="text"   />
                </div>
              </div>

              <div class="field lg:col-6" v-if="eja2.atividade_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                <label>Nota da {{eja2.atividades_nome_exib}}:</label>
                <pm-InputNumber v-model="eja2.atividades" mode="decimal" showButtons :min="0" :max="100" />
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <!--avaliação-->
            <div class="field col-12">
              <div class="p-fluid formgrid grid">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="field col-12 lg:col-12 mt-3" v-if="eja2.avaliacao_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="eja2.avaliacao_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="eja2.avaliacao_selec == 1" style="margin-bottom: -12px; padding-top: 20px;">
                  <label>Nota da {{eja2.avaliacao_nome_exib}}:</label>
                  <pm-InputNumber v-model="eja2.avaliacao" mode="decimal" showButtons :min="0" :max="100" />
                </div>
              </div>

            </div>

            <div class="col-12 row mt-5">
              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px;">
                <label>Média para ser promovido:</label>
                <pm-InputNumber v-model="eja2.media" mode="decimal" showButtons :min="0" :max="100" />
              </div>

              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px; padding-left: 0px;">
                <label>Porcentagem de Falta:</label>
                <pm-InputNumber v-model="eja2.porcentagem_falta" mode="decimal" showButtons :min="0" :max="100" />
              </div>
            </div>

          </div>

          <!--recuperação-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-4 md:col-4" style="padding: 0px; margin:0px;">

                <div class="flex" style=" padding: 8px;">
                  <label >Possuirá Recuperação? </label>
                  <div style="margin:0px" v-for="n of eja2.tipos" :key="n.tera">
                    <pm-RadioButton :inputId="n.tera" name="n" :value="n.tera" v-model="eja2.recuperacao_selec"/>
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>

              </div>
              <div class="row" style="margin:0px">
                <div class="field col-12 md:col-8 lg:col-4" v-if="eja2.recuperacao_selec == '1'">
                  <label>A recuperaçãos será?</label>
                  <pm-Dropdown v-if="eja2.recuperacao_bimestral == 1" v-model="eja2.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[1].nome"/>
                  <pm-Dropdown v-else-if="eja2.recuperacao_bimestral == 0" v-model="eja2.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[0].nome"/>
                  <pm-Dropdown v-else v-model="eja2.tipo_recuperacao" optionLabel="nome" :options="recuperacao" :placeholder="'Selecione uma opção'"/>
                </div>

                <div class="field col-12 md:col-8" v-if="eja2.tipo_recuperacao.nome != null && eja2.recuperacao_selec == '1'">
                  <label>Selecione o que a recuperação irá substitui!</label>
                  <pm-Dropdown v-model="eja2.aRecuperacaoSubstitui" optionLabel="nome" :options="recuperacaoSubstitui" placeholder="Selecione uma das opções" />
                </div>

              </div>

              <div class="field col-12" v-if="eja2.recuperacao_selec == true">
                <label>Peso das Recuperações:</label>
                <div class="p-fluid formgrid grid" v-if="eja2.tipo_recuperacao.nome == 'Bimestral'">
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="eja2.tera_primeiro_bimestre">
                    <label>1° Bimestre</label>
                    <pm-InputNumber v-model="eja2.recupercao_1b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="eja2.tera_segundo_bimestre">
                    <label>2° Bimestre</label>
                    <pm-InputNumber v-model="eja2.recupercao_2b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" style="padding-top: 0px;" v-if="eja2.tera_recuperacao_final">
                    <label>Final</label>
                    <pm-InputNumber v-model="eja2.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>
                <div class="p-fluid formgrid grid" v-if="eja2.tipo_recuperacao.nome == 'Semestral'">
                  <div class="field lg:col-2" v-if="eja2.tera_primeiro_semestre" style="padding-top: 0px;">
                    <label>1° Semestre</label>
                    <pm-InputNumber v-model="eja2.primeiro_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2" v-if="eja2.tera_recuperacao_final"  style="padding-top: 0px;">
                    <label>Recuperação Final</label>
                    <pm-InputNumber v-model="eja2.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

        <somaNotas  :fund="eja2" />
      </pm-Panel>

      <pm-Panel header="EJA (Fases Finais)" class="col-md-12 mt-5" :collapsed="collapse_eja1" @click="mudarCollapse(4)">
        <div class="row">
          <!--simulado-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row" style="margin:0px">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">

                  <div class="flex" style=" padding: 8px;">
                    <label style="margin-left: 0px;">Possuirá Simulado?</label>
                    <div v-for="n of eja1.tipos" :key="n.tera">
                      <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="eja1.simulado_selec"  />
                      <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                    </div>
                  </div>

                  <div class="field col-12 lg:col-12 mt-3" v-if="eja1.simulado_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="eja1.simulado_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="eja1.simulado_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                  <label>Nota do {{eja1.simulado_nome_exib}}:</label>
                  <pm-InputNumber   v-model="eja1.simulado" mode="decimal" showButtons :min="0" :max="100" />

                </div>
              </div>
            </div>
          </div>

          <!--atividades-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-12 md:col-6" style="padding-top: 15px;">

                <div class="flex" style="padding: 8px;">
                  <label style="margin-left: 0px;">Possuirá Atividades Diversificadas?</label>
                  <div v-for="n of eja1.tipos" :key="n.tera">
                    <pm-RadioButton  :inputId="n.tera" name="n" :value="n.tera" v-model="eja1.atividade_selec"  />
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>
                <div class="field col-12 lg:col-12 mt-3" v-if="eja1.atividade_selec == 1">
                  <label >Nome Exibido:</label>
                  <pm-InputText v-model="eja1.atividades_nome_exib"  type="text"   />
                </div>
              </div>

              <div class="field lg:col-6" v-if="eja1.atividade_selec == 1" style="margin-bottom: -12px; padding-top: 56px;">
                <label>Nota da {{eja1.atividades_nome_exib}}:</label>
                <pm-InputNumber v-model="eja1.atividades" mode="decimal" showButtons :min="0" :max="100" />
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <!--avaliação-->
            <div class="field col-12">
              <div class="p-fluid formgrid grid">
                <div class="row field col-12 md:col-6 pt-3" style="margin-bottom:-12px">
                  <div class="field col-12 lg:col-12 mt-3" v-if="eja1.avaliacao_selec == 1">
                    <label >Nome Exibido:</label>
                    <pm-InputText v-model="eja1.avaliacao_nome_exib"  type="text"   />
                  </div>
                </div>

                <div class="field lg:col-6" v-if="eja1.avaliacao_selec == 1" style="margin-bottom: -12px; padding-top: 20px;">
                  <label>Nota da {{eja1.avaliacao_nome_exib}}:</label>
                  <pm-InputNumber v-model="eja1.avaliacao" mode="decimal" showButtons :min="0" :max="100" />
                </div>
                <div class="field col-12"></div>
              </div>
            </div>

            <div class="col-12 row mt-5">
              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px;">
                <label>Média para ser promovido:</label>
                <pm-InputNumber v-model="eja1.media" mode="decimal" showButtons :min="0" :max="100" />
              </div>

              <div class="field col-12 md:col-8 lg:col-6" style="padding-right: 12px; padding-left: 0px;">
                <label>Porcentagem de Falta:</label>
                <pm-InputNumber v-model="eja1.porcentagem_falta" mode="decimal" showButtons :min="0" :max="100" />
              </div>
            </div>

          </div>

          <!--recuperação-->
          <div class="field col-6">
            <div class="p-fluid formgrid grid">
              <div class="row field col-4 md:col-4" style="padding: 0px; margin:0px">

                <div class="flex" style=" padding: 8px;">
                  <label >Possuirá Recuperação? </label>
                  <div style="margin:0px" v-for="n of eja1.tipos" :key="n.tera">
                    <pm-RadioButton :inputId="n.tera" name="n" :value="n.tera" v-model="eja1.recuperacao_selec"/>
                    <label class="ml-1" :for="n.tera">{{n.nome}}</label>
                  </div>
                </div>

              </div>
              <div class="row" style="margin:0px">
                <div class="field col-12 md:col-8 lg:col-4" v-if="eja1.recuperacao_selec == '1'">
                  <label>A recuperação será?</label>
                  <pm-Dropdown v-if="eja1.recuperacao_bimestral == 1" v-model="eja1.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[1].nome"/>
                  <pm-Dropdown v-else-if="eja1.recuperacao_bimestral == 0" v-model="eja1.tipo_recuperacao" optionLabel="nome" :options="recuperacao"  :placeholder="recuperacao[0].nome"/>
                  <pm-Dropdown v-else v-model="eja1.tipo_recuperacao" optionLabel="nome" :options="recuperacao" :placeholder="'Selecione uma opção'"/>
                </div>

                <div class="field col-12 md:col-7" v-if="eja1.tipo_recuperacao.nome != null && eja1.recuperacao_selec == '1'">
                  <label>Selecione o que a recuperação irá substitui!</label>
                  <pm-Dropdown v-model="eja1.aRecuperacaoSubstitui" optionLabel="nome" :options="recuperacaoSubstitui" placeholder="Selecione uma das opções" />
                </div>

              </div>
              <div class="field col-12" v-if="eja1.recuperacao_selec == true">
                <label>Peso das Recuperações:</label>
                <div class="p-fluid formgrid grid" v-if="eja1.tipo_recuperacao.nome == 'Bimestral'">
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="eja1.tera_primeiro_bimestre">
                    <label>1° Bimestre</label>
                    <pm-InputNumber v-model="eja1.recupercao_1b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                  <div class="field lg:col-2"  style="padding-top: 0px;" v-if="eja1.tera_segundo_bimestre">
                    <label>2° Bimestre</label>
                    <pm-InputNumber v-model="eja1.recupercao_2b" mode="decimal" showButtons :min="0" :max="100" />
                  </div>

                  <div class="field lg:col-2" style="padding-top: 0px;" v-if="eja1.tera_recuperacao_final">
                    <label>Final</label>
                    <pm-InputNumber v-model="eja1.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>
                <div class="p-fluid formgrid grid" v-if="eja1.tipo_recuperacao.nome == 'Semestral'">
                  <div class="field lg:col-2" v-if="eja1.tera_primeiro_semestre" style="padding-top: 0px;">
                    <label>1° Semestre</label>
                    <pm-InputNumber v-model="eja1.primeiro_semestre" mode="decimal" showButtons :min="0" :max="100" />
                  </div>

                  <div class="field lg:col-2" v-if="eja1.tera_recuperacao_final"  style="padding-top: 0px;">
                    <label>Recuperação Final</label>
                    <pm-InputNumber v-model="eja1.recuperacao_final_nota" mode="decimal" showButtons :min="0" :max="100" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <somaNotas  :fund="eja1" />
      </pm-Panel>

   


      <div class="field col-12">
      </div>

      <br>

      <div v-if="ehParaEditar == false" class="grid grid-nogutter justify-content-between">
        <pm-Button  class="p-button-success"
          @click="enviarDados"
          label="Cadastrar Configuração"
          icon="pi pi-check" iconPos="right"
        />
      </div>
      <div v-if="ehParaEditar == true && autorizado == 0" class="grid grid-nogutter justify-content-between">
        <pm-Button  class="p-button-success"
          @click="editarDados"
          label="Editar Configuração"
          icon="pi pi-check" iconPos="right"
        />
      </div>
    </div>
  </section>

  <section>
    <div v-if="carregarPagina" class="col-md-12" style="text-align: center;padding-top: 140px;">
      <pm-ProgressSpinner />
    </div>
  </section>
</template>

<script>

import somaNotas from '@/components/configuracao/somaNotas.vue'

import { Calendario } from "@/class/calendario";
import { Configuracao } from "@/class/configuracao";
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    somaNotas
  },
  props: {
    id_calendario:null,
  },
  data () {
    return {
      cadastrarOuEditar:1,
      carregarPagina:false,
      meses : [
        {numero:'01', palavra: 'janeiro'},
        {numero:'02', palavra: 'fevereiro'},
        {numero:'03', palavra: 'marco'},
        {numero:'04', palavra: 'abril'},
        {numero:'05', palavra: 'maio'},
        {numero:'06', palavra: 'junho'},
        {numero:'07', palavra: 'julho'},
        {numero:'08', palavra: 'agosto'},
        {numero:'09', palavra: 'setembro'},
        {numero:'10', palavra: 'outubro'},
        {numero:'11', palavra: 'novembro'},
        {numero:'12', palavra: 'dezembro'},
      ],
      recuperacaoSubstitui : [
        {id: 1, nome: 'Simulado'},
        {id: 2, nome: 'Ativadade Diversificada'},
        {id: 3, nome: 'Avaliação'},
        {id: 4, nome: 'Simulado e Ativadade Diversificada'},
        {id: 5, nome: 'Simulado e Avaliação'},
        {id: 6, nome: 'Atividade Diversificada e Avaliação'},
        {id: 7, nome: 'Simulado, Atividade Diversificada e Avaliação'},
      ],
      configuracao:{
        calendario_letivos_id:0,
        tipo_ano:"Bimestre",
        tipo_ano_nome_exib:"Período",
        data_inicial_matricula:null,
        data_final_matricula:null,

        b1_inicio:null,
        b1_final:null,
        b2_inicio:null,
        b2_final:null,
        b3_inicio:null,
        b3_final:null,
        b4_inicio:null,
        b4_final:null,
        test:null,

        corPer1:'2279e5',
        corPer2:'2279e5',
        corPer3:'2279e5',
        corPer4:'2279e5'

      },
      educ:{
        tipo_segmento:0,
        porcentagem:60,
        configuracaos_id:0
      },
      recuperacao:[
        {id: 0, nome: 'Semestral'},
        {id: 1, nome: 'Bimestral'}
      ],

      fund1:{
        configuracaos_id:0,
        tipo_segmento:1,
        porcentagem:75,
        porcentagem_falta: 75,
        tipos:[
          {tera: 0, nome: "Não"},
          {tera: 1, nome: "Sim"}
        ],

        //simulado
        simulado_selec: null,
        tera_simulado:"1",
        simulado_nome_exib: "Simulado",
        simulado:20,
        recupercao_final:0,
        media:50,

        //atividade diversificade
        atividade_selec:null,
        tera_atividades:"1",
        atividades_nome_exib: "Atividade Diversificada",
        atividades:30,

        recuperacao_bimestral:null,
        //Avaliacao
        avaliacao_selec:null,
        tera_avaliacao:"1",
        avaliacao_nome_exib: "Avaliação",
        avaliacao:50,

        //Recuperacao Tipo(bimestral ou semestral)
        recuperacao_selec: null,
        tera_recuperacao:"1",
        // tipo_recuperacao:"Semestral",
        tipo_recuperacao:[],


        tera_recuperacao_final:true,
        recuperacao_final_nota:100,

        //Se for semestral
        tera_primeiro_semestre: true,
        tera_segundo_semestre: true,
        primeiro_semestre:100,
        segundo_semestre:100,


        //Se for bimestral
        tera_primeiro_bimestre: true,
        tera_segundo_bimestre: true,
        tera_terceiro_bimestre: true,
        tera_quarto_bimestre:true,

        recupercao_1b:100,
        recupercao_2b:100,
        recupercao_3b:100,
        recupercao_4b:100,

        aRecuperacaoSubstitui:{ "id": 7, "nome": "Simulado, Atividade Diversificada e Avaliação"}
      },
      fund2:{
        configuracaos_id:0,
        tipo_segmento:2,
        porcentagem:75,
        porcentagem_falta: 75,
        tipos:[
          {tera: 0, nome: "Não"},
          {tera: 1, nome: "Sim"}
        ],

        //simulado
        simulado_selec:null,
        tera_simulado:"1",
        simulado_nome_exib: "Simulado",
        simulado:20,
        recupercao_final:0,
        media:50,

        //atividade diversificade
        atividade_selec:null,
        tera_atividades:"1",
        atividades_nome_exib: "Atividade Diversificada",
        atividades:30,

        recuperacao_bimestral:null,
        //Avaliacao
        avaliacao_selec:null,
        tera_avaliacao:"1",
        avaliacao_nome_exib: "Avaliação",
        avaliacao:50,

        //Recuperacao Tipo(bimestral ou semestral)
        recuperacao_selec: null,
        tera_recuperacao:"1",
        // tipo_recuperacao:"Semestral",
        tipo_recuperacao:[],


        tera_recuperacao_final:true,
        recuperacao_final_nota:100,

        //Se for semestral
        tera_primeiro_semestre: true,
        tera_segundo_semestre: true,
        primeiro_semestre:100,
        segundo_semestre:100,


        //Se for bimestral
        tera_primeiro_bimestre: true,
        tera_segundo_bimestre: true,
        tera_terceiro_bimestre: true,
        tera_quarto_bimestre:true,

        recupercao_1b:100,
        recupercao_2b:100,
        recupercao_3b:100,
        recupercao_4b:100,

        aRecuperacaoSubstitui:{ "id": 7, "nome": "Simulado, Atividade Diversificada e Avaliação"}
      },
      eja1:{
        configuracaos_id:0,
        tipo_segmento:3,
        porcentagem:75,
        porcentagem_falta: 75,
        tipos:[
          {tera: 0, nome: "Não"},
          {tera: 1, nome: "Sim"}
        ],

        //simulado
        simulado_selec:null,
        tera_simulado:"1",
        simulado_nome_exib: "Simulado",
        simulado:20,
        recupercao_final:0,
        media:50,

        //atividade diversificade
        atividade_selec:null,
        tera_atividades:"1",
        atividades_nome_exib: "Atividade Diversificada",
        atividades:30,

        recuperacao_bimestral:null,

        //Avaliacao
        avaliacao_selec:null,
        tera_avaliacao:"1",
        avaliacao_nome_exib: "Avaliação",
        avaliacao:50,

        //Recuperacao Tipo(bimestral ou semestral)
        recuperacao_selec: null,
        tera_recuperacao:"1",
        // tipo_recuperacao:"Semestral",
        tipo_recuperacao:[],


        tera_recuperacao_final:true,
        recuperacao_final_nota:100,

        //Se for semestral
        tera_primeiro_semestre: true,
        tera_segundo_semestre: true,
        primeiro_semestre:100,
        segundo_semestre:100,


        //Se for bimestral
        tera_primeiro_bimestre: true,
        tera_segundo_bimestre: true,
        tera_terceiro_bimestre: true,
        tera_quarto_bimestre:true,

        recupercao_1b:100,
        recupercao_2b:100,
        recupercao_3b:100,
        recupercao_4b:100,

        aRecuperacaoSubstitui:{ "id": 7, "nome": "Simulado, Atividade Diversificada e Avaliação"}
      },
      eja2:{
        configuracaos_id:0,
        tipo_segmento:3,
        porcentagem:75,
        porcentagem_falta: 75,
        tipos:[
          {tera: 0, nome: "Não"},
          {tera: 1, nome: "Sim"}
        ],

        //simulado
        simulado_selec:null,
        tera_simulado:"1",
        simulado_nome_exib: "Simulado",
        simulado:20,
        recupercao_final:0,
        media:50,

        //atividade diversificade
        atividade_selec:null,
        tera_atividades:"1",
        atividades_nome_exib: "Atividade Diversificada",
        atividades:30,

       recuperacao_bimestral:null,
        //Avaliacao
        avaliacao_selec:null,
        tera_avaliacao:"1",
        avaliacao_nome_exib: "Avaliação",
        avaliacao:50,

        //Recuperacao Tipo(bimestral ou semestral)
        recuperacao_selec: null,
        tera_recuperacao:"1",
        // tipo_recuperacao:"Semestral",
        tipo_recuperacao:[],


        tera_recuperacao_final:true,
        recuperacao_final_nota:100,

        //Se for semestral
        tera_primeiro_semestre: true,
        tera_segundo_semestre: true,
        primeiro_semestre:100,
        segundo_semestre:100,


        //Se for bimestral
        tera_primeiro_bimestre: true,
        tera_segundo_bimestre: true,
        tera_terceiro_bimestre: true,
        tera_quarto_bimestre:true,

        recupercao_1b:100,
        recupercao_2b:100,
        recupercao_3b:100,
        recupercao_4b:100,

        aRecuperacaoSubstitui:{ "id": 7, "nome": "Simulado, Atividade Diversificada e Avaliação"}
      },
      calendarioAtualAll:[],
      validationErrors: {},
      confiValidation: {
        finalUmOk:true,
        finalDoisOk:true,
        finalTresOk:true,
        finalQuatroOk:true,

        //pode passar para a proxima selecao
        primeiraSelecaoOk:false,
        segundaSelecaoOk:false,
        terceiraSelecaoOk:false,
        quartaSelcaoOk:false,

        //A selecao foi feita corretamente
        primeiraSelecaoInicioOk:-1,
        primeiraSelecaoFinalOk:-1,

        segundaSelecaoInicioOk:-1,
        segundaSelecaoFinalOk:-1,

        terceiraSelecaoInicioOk:-1,
        terceiraSelecaoFinalOk:-1,

        QuartaSelecaoInicioOk:-1,
        QuartaSelecaoFinalOk:-1

      },
      aConfiguracaoEstaOk:-1,
      configResult:'',
      ehParaEditar:false,
      autorizado: null,

      //collapse
      collapse_geral: false,
      collapse_geral2: true,
      collapse_infantil: true,
      collapse_fund1: true,
      collapse_fund2: true,
      collapse_eja1: true,
      collapse_eja2: true,
      ano: '',
    }
  },

  methods:{
    mudarCollapse(quem){
      if(quem == 0){
        this.collapse_geral = false;
        this.collapse_geral2 = true;
        this.collapse_infantil = true;
        this.collapse_fund1 = true;
        this.collapse_fund2 = true;
        this.collapse_eja1 = true;
        this.collapse_eja2 = true;
      }
      if(quem == 1){
        this.collapse_geral = true;
        this.collapse_geral2 = true;
        this.collapse_infantil = false;
        this.collapse_fund1 = true;
        this.collapse_fund2 = true;
        this.collapse_eja1 = true;
        this.collapse_eja2 = true;
      }
      if(quem == 2){
        this.collapse_geral = true;
        this.collapse_geral2 = true;
        this.collapse_infantil = true;
        this.collapse_fund1 = false;
        this.collapse_fund2 = true;
        this.collapse_eja1 = true;
        this.collapse_eja2 = true;
      }
      if(quem == 3){
        this.collapse_geral = true;
        this.collapse_geral2 = true;
        this.collapse_infantil = true;
        this.collapse_fund1 = true;
        this.collapse_fund2 = false;
        this.collapse_eja1 = true;
        this.collapse_eja2 = true;
      }
      if(quem == 4){
        this.collapse_geral = true;
        this.collapse_geral2 = true;
        this.collapse_infantil = true;
        this.collapse_fund1 = true;
        this.collapse_fund2 = true;
        this.collapse_eja1 = false;
        this.collapse_eja2 = true;
      }
      if(quem == 5){
        this.collapse_geral = true;
        this.collapse_geral2 = true;
        this.collapse_infantil = true;
        this.collapse_fund1 = true;
        this.collapse_fund2 = true;
        this.collapse_eja1 = true;
        this.collapse_eja2 = false;
      }
      if(quem == 6){
        this.collapse_geral = true;
        this.collapse_geral2 = false;
        this.collapse_infantil = true;
        this.collapse_fund1 = true;
        this.collapse_fund2 = true;
        this.collapse_eja1 = true;
        this.collapse_eja2 = true;
      }
    },
    buscarTipoRecuperacaoSubstitui(id) {
      for (let i = 0; i < this.recuperacaoSubstitui.length; i++) {
        if (id == this.recuperacaoSubstitui[i].id)
          {return this.recuperacaoSubstitui[i];}
      }
    },
    converterParaFormatoUSA(date){
        if (date instanceof Date) {
          return date.toISOString().split('T')[0];
        }
        const dataQuebrada = date.split('/');
        let dia = dataQuebrada[0];
        let mes = dataQuebrada[1];
        const ano = dataQuebrada[2];
        if (dia.length == 1)
          {dia = '0' + dia;}

        if (mes.length == 1)
          {mes = '0' + mes;}

        return ano+'-'+mes+'-'+dia;
    },
    async editarDados() {
      const novo = {
        calendario_letivos_id: this.configuracao.calendario_letivos_id,
        tipo_ano: this.configuracao.tipo_ano,
        tipo_ano_nome_exib: this.configuracao.tipo_ano_nome_exib,
        data_inicial_matricula: this.converterParaFormatoUSA(this.configuracao.data_inicial_matricula),
        data_final_matricula: this.converterParaFormatoUSA(this.configuracao.data_final_matricula),
        id: this.configuracao.id,
        ano: this.ano
      }
      const data = await Configuracao.editarConfiguracaoConfig(novo);


      await this.enviarDados();
    },
    receberDados(exibirValores, configuracao) {
      exibirValores.configuracaos_id = configuracao.configuracaos_id;
      exibirValores.tipo_segmento =  configuracao.tipo_segmento;
      exibirValores.tera_simulado = configuracao.tera_simulado;
      exibirValores.tera_atividades = configuracao.tera_atividades;
      exibirValores.tera_avaliacao = configuracao.tera_avaliacao;
      exibirValores.tera_recuperacao =  configuracao.tera_recuperacao;
      exibirValores.eja2 = configuracao.eja2;
      exibirValores.id = configuracao.id;
      exibirValores.simulado_nome_exib  = configuracao.simulado_nome_exib;
      exibirValores.atividades_nome_exib = configuracao.atividades_nome_exib;
      exibirValores.avaliacao_nome_exib =  configuracao.avaliacao_nome_exib;
      exibirValores.porcentagem_falta = configuracao.porcentagem_falta;

      exibirValores.simulado_selec = configuracao.tera_simulado;
      exibirValores.atividade_selec = configuracao.tera_atividades;
      exibirValores.avaliacao_selec = configuracao.tera_avaliacao;
      exibirValores.recuperacao_selec = configuracao.tera_recuperacao;

      exibirValores.aRecuperacaoSubstitui =  this.buscarTipoRecuperacaoSubstitui(configuracao.notas.notas_substituir_recup);
      exibirValores.notas_substituir_recup =  configuracao.notas.notas_substituir_recup;
      exibirValores.simulado = configuracao.notas.simulado;
      exibirValores.atividades = configuracao.notas.atividades;
      exibirValores.avaliacao =  configuracao.notas.avaliacao;
      exibirValores.media = configuracao.notas.media;

      exibirValores.configuracao_segmentos_id  = configuracao.notas.configuracao_segmentos_id;
      exibirValores.recuperacao_bimestral = configuracao.notas.recuperacao_bimestral;
      exibirValores.recupercao_1b  = configuracao.notas.recuperacao_nota_b1;
      exibirValores.recupercao_2b =  configuracao.notas.recuperacao_nota_b2;
      exibirValores.recupercao_3b =  configuracao.notas.recuperacao_nota_b3;
      exibirValores.recupercao_4b =  configuracao.notas.recuperacao_nota_b4;

      exibirValores.recuperacao_semestral = configuracao.notas.recuperacao_semestral;
      exibirValores.recuperacao_nota_s1 = configuracao.notas.recuperacao_nota_s1;
      exibirValores.recuperacao_nota_s2 = configuracao.notas.recuperacao_nota_s2;





      exibirValores.tera_recuperacao_final = configuracao.notas.recuperacao_final;
      exibirValores.recuperacao_final_nota = configuracao.notas.recuperacao_nota_final;

      exibirValores.primeiro_semestre =  configuracao.notas.recuperacao_nota_s1;
      exibirValores.segundo_semestre =  configuracao.notas.recuperacao_nota_s2;

      if (exibirValores.recuperacao_bimestral) {
        exibirValores.tipo_recuperacao = this.recuperacao[1];
      }
      else {
        exibirValores.tipo_recuperacao = this.recuperacao[0];
      }

    },
    async buscarDados(configuracao) {
      //configuracao
      this.confiValidation.primeiraSelecaoOk = true;
      this.confiValidation.segundaSelecaoOk = true;
      this.confiValidation.terceiraSelecaoOk = true;
      this.confiValidation.quartaSelecaoOk = true;

      this.configuracao.calendario_letivos_id = configuracao.calendario_letivos_id;
      this.configuracao.tipo_ano = "Bimestre",
      this.configuracao.tipo_ano_nome_exib = configuracao.tipo_ano_nome_exib;

      if(configuracao.data_inicial_matricula != null){
        this.configuracao.data_inicial_matricula = new Date(configuracao.data_inicial_matricula).toLocaleDateString('pt-BR');
        // this.configuracao.data_inicial_matricula = configuracao.data_inicial_matricula.substr(0, 10);
      }else{ this.configuracao.data_inicial_matricula = null; }
      if(configuracao.data_final_matricula != null){
        this.configuracao.data_final_matricula = new Date(configuracao.data_final_matricula).toLocaleDateString('pt-BR');
        // this.configuracao.data_final_matricula = configuracao.data_final_matricula.substr(0, 10);
      }else{ this.configuracao.data_final_matricula = null; }

      this.configuracao.b1_inicio = new Date(configuracao.b1_inicio).toLocaleDateString('pt-BR');
      this.configuracao.b1_final = new Date(configuracao.b1_final).toLocaleDateString('pt-BR');

      this.configuracao.b2_inicio = new Date(configuracao.b2_inicio).toLocaleDateString('pt-BR');
      this.configuracao.b2_final = new Date(configuracao.b2_final).toLocaleDateString('pt-BR');

      this.configuracao.b3_inicio = new Date(configuracao.b3_inicio).toLocaleDateString('pt-BR');
      this.configuracao.b3_final = new Date(configuracao.b3_final).toLocaleDateString('pt-BR');

      this.configuracao.b4_inicio = new Date(configuracao.b4_inicio).toLocaleDateString('pt-BR');
      this.configuracao.b4_final = new Date(configuracao.b4_final).toLocaleDateString('pt-BR');


      this.configuracao.id = configuracao.id;

      this.configuracao.corPer1 = configuracao.colorpicker_1b;
      this.configuracao.corPer2 = configuracao.colorpicker_2b;
      this.configuracao.corPer3 = configuracao.colorpicker_3b;
      this.configuracao.corPer4 = configuracao.colorpicker_4b;

      //creche
      const obj1 = configuracao.info.find(element => element.tipo_segmento == 0);
      this.educ.tipo_segmento = obj1.tipo_segmento;
      this.educ.porcentagem = obj1.porcentagem_falta;
      this.educ.id = obj1.id;
      this.educ.configuracaos_id = obj1.configuracaos_id;


      //fudamental 1
      const obj2 = configuracao.info.find(element => element.tipo_segmento == 1);
      this.receberDados(this.fund1, obj2);
      //fundamental 2
      const obj3 = configuracao.info.find(element => element.tipo_segmento == 2);
      this.receberDados(this.fund2, obj3);

      //eja 1
      const obj4 = configuracao.info.find(element => element.tipo_segmento == 3 && element.eja2 == 1);
      this.receberDados(this.eja1, obj4);

      //eja 2
      const obj5 = configuracao.info.find(element => element.tipo_segmento == 3 && element.eja2 == 0);
      this.receberDados(this.eja2, obj5);
    },
    verificarDatasPeriodos(tipo) {
      this.aConfiguracaoEstaOk = 1;
      if  (this.validationErrors.b1_inicio)
        {return false;}

      if  (this.validationErrors.b1_final)
        {return false;}

      if  (this.validationErrors.b2_inicio)
        {return false;}

      if  (this.validationErrors.b2_final)
        {return false;}

      if  (this.validationErrors.b3_inicio)
        {return false;}

      if  (this.validationErrors.b3_final)
        {return false;}

      if  (this.validationErrors.b4_inicio)
        {return false;}

      if  (this.validationErrors.b4_final)
        {return false;}

      if (!this.confiValidation.finalUmOk)
        {return false;}

      if (this.confiValidation.segundaSelecaoInicioOk == 0)
        {return false;}

      if (!this.confiValidation.finalDoisOk)
        {return false;}

      if (this.confiValidation.segundaSelecaoFinalOk == 0)
        {return false;}

      if (this.confiValidation.terceiraSelecaoInicioOk == 0)
        {return false;}

      if (!this.confiValidation.finalTresOk)
        {return false;}

      if (this.confiValidation.terceiraSelecaoFinalOk == 0)
        {return false;}

      if (this.confiValidation.QuartaSelecaoInicioOk == 0)
        {return false;}

      if (!this.confiValidation.finalQuatroOk)
        {return false;}

      if (!this.confiValidation.QuartaSelecaoFinalOk)
        {return false;}
      return true;

    },
    verificarSomaDasNotas(tipo) {

      if (this.fund1.simulado_selec === 0) {
        this.fund1.simulado = 0;
      }
      if (this.fund1.avaliacao_selec === 0) {
        this.fund1.avaliacao = 0;
      }
      if (this.fund1.atividade_selec === 0) {
        this.fund1.atividades = 0;
      }

      if (this.fund2.simulado_selec === 0) {
        this.fund2.simulado = 0;
      }
      if (this.fund2.avaliacao_selec === 0) {
        this.fund2.avaliacao = 0;
      }
      if (this.fund2.atividade_selec === 0) {
        this.fund2.atividades = 0;
      }

      if (this.eja1.simulado_selec === 0) {
        this.eja1.simulado = 0;
      }
      if (this.eja1.avaliacao_selec === 0) {
        this.eja1.avaliacao = 0;
      }
      if (this.eja1.atividade_selec === 0) {
        this.eja1.atividades = 0;
      }

      if (this.eja2.simulado_selec === 0) {
        this.eja2.simulado = 0;
      }
      if (this.eja2.avaliacao_selec === 0) {
        this.eja2.avaliacao = 0;
      }
      if (this.eja2.atividade_selec === 0) {
        this.eja2.atividades = 0;
      }


      const soma1 =  this.fund1.simulado + this.fund1.atividades + this.fund1.avaliacao;
      const soma2 =  this.fund2.simulado + this.fund2.atividades + this.fund2.avaliacao;
      const soma3 =  this.eja1.simulado + this.eja1.atividades + this.eja1.avaliacao;
      const soma4 =  this.eja2.simulado + this.eja2.atividades + this.eja2.avaliacao;

      return (soma1 == 100 && soma2 == 100 && soma3 == 100 && soma4 == 100);
    },
    async enviarConfiguracoes(tipo) {
      const novo = {
        // calendario_letivos_id: this.configuracao.calendario_letivos_id,
        ano: this.ano,
        tipo_ano: this.configuracao.tipo_ano,
        tipo_ano_nome_exib: this.configuracao.tipo_ano_nome_exib,

        data_inicial_matricula:this.configuracao.data_inicial_matricula.toLocaleDateString('sv-SE'),
        data_final_matricula:this.configuracao.data_final_matricula.toLocaleDateString('sv-SE'),

      }
      const data = await Configuracao.criarConfiguracao(novo);
      this.configResult = data.data;
    },
    async informacoesOkFundamentalUm(tipo) {
      const novo = {
        configuracaos_id:  this.configResult.id,
        tipo_segmento:  this.fund1.tipo_segmento,
        // tera_simulado:  this.fund1.tera_simulado,
        tera_simulado:  this.fund1.simulado_selec,
        tera_atividades:  this.fund1.atividade_selec,
        tera_avaliacao:  this.fund1.avaliacao_selec,
        tera_recuperacao:  this.fund1.recuperacao_selec,
        eja2:0,
        simulado_nome_exib:  this.fund1.simulado_nome_exib,
        atividades_nome_exib:  this.fund1.atividades_nome_exib,
        avaliacao_nome_exib:  this.fund1.avaliacao_nome_exib,
        porcentagem_falta:  this.fund1.porcentagem_falta,



        notas_substituir_recup:  this.fund1.aRecuperacaoSubstitui.id,

        simulado:  this.fund1.simulado,
        atividades:  this.fund1.atividades,
        avaliacao:  this.fund1.avaliacao,
        media:  this.fund1.media,


        recuperacao_bimestral: (this.fund1.tipo_recuperacao.nome == 'Bimestral' ? 1 : 0),
        recuperacao_nota_b1:   this.fund1.recupercao_1b,
        recuperacao_nota_b2:   this.fund1.recupercao_2b,
        recuperacao_nota_b3:   this.fund1.recupercao_3b,
        recuperacao_nota_b4:   this.fund1.recupercao_4b,

        recuperacao_semestral: (this.fund1.tipo_recuperacao.nome == 'Semestral' ? 1 : 0),
        recuperacao_nota_s1:   this.fund1.primeiro_semestre,
        recuperacao_nota_s2:   this.fund1.segundo_semestre,

        recuperacao_final:  1,
        recuperacao_nota_final: this.fund1.recuperacao_final_nota,
      }
      if (tipo == 2) {
        novo.id = this.fund1.id;
        novo.configuracaos_id = this.fund1.configuracaos_id;
      }
      let data = '';
      if (tipo == 1)
        {data = await Configuracao.criarConfiguracaoPorSegmento(novo);}
      else
        {data = await Configuracao.editarConfiguracaoNotas(novo);}

      return false;
    },
    async informacoesOkFundamentalDois(tipo) {
      const novo = {
        configuracaos_id:  this.configResult.id,
        tipo_segmento:  this.fund2.tipo_segmento,
        // tera_simulado:  this.fund2.tera_simulado,
        tera_simulado:  this.fund2.simulado_selec,
        tera_atividades:  this.fund2.atividade_selec,
        tera_avaliacao:  this.fund2.avaliacao_selec,
        tera_recuperacao:  this.fund2.recuperacao_selec,
        eja2:0,
        simulado_nome_exib:  this.fund2.simulado_nome_exib,
        atividades_nome_exib:  this.fund2.atividades_nome_exib,
        avaliacao_nome_exib:  this.fund2.avaliacao_nome_exib,
        porcentagem_falta:  this.fund2.porcentagem_falta,



        notas_substituir_recup:  this.fund2.aRecuperacaoSubstitui.id,

        simulado:  this.fund2.simulado,
        atividades:  this.fund2.atividades,
        avaliacao:  this.fund2.avaliacao,
        media:  this.fund2.media,


        recuperacao_bimestral: (this.fund2.tipo_recuperacao.nome == 'Bimestral' ? 1 : 0),
        recuperacao_nota_b1:   this.fund2.recupercao_1b,
        recuperacao_nota_b2:   this.fund2.recupercao_2b,
        recuperacao_nota_b3:   this.fund2.recupercao_3b,
        recuperacao_nota_b4:   this.fund2.recupercao_4b,

        recuperacao_semestral: (this.fund2.tipo_recuperacao.nome == 'Semestral' ? 1 : 0),
        recuperacao_nota_s1:   this.fund2.primeiro_semestre,
        recuperacao_nota_s2:   this.fund2.primeiro_semestre,



        recuperacao_final:  1,
        recuperacao_nota_final: this.fund2.recuperacao_final_nota,
      }
      if (tipo == 2) {
        novo.id = this.fund2.id;
        novo.configuracaos_id = this.fund2.configuracaos_id;
      }
      let data = '';
      if (tipo == 1)
        {data = await Configuracao.criarConfiguracaoPorSegmento(novo);}
      else
        {data = await Configuracao.editarConfiguracaoNotas(novo);}
    },
    async informacoesOkEja1(tipo) {
      const novo = {
        configuracaos_id:  this.configResult.id,
        tipo_segmento:  this.eja1.tipo_segmento,
        // tera_simulado:  this.eja1.tera_simulado,
        tera_simulado:  this.eja1.simulado_selec,
        tera_atividades:  this.eja1.atividade_selec,
        tera_avaliacao:  this.eja1.avaliacao_selec,
        tera_recuperacao:  this.eja1.recuperacao_selec,
        eja2:1,
        simulado_nome_exib:  this.eja1.simulado_nome_exib,
        atividades_nome_exib:  this.eja1.atividades_nome_exib,
        avaliacao_nome_exib:  this.eja1.avaliacao_nome_exib,
        porcentagem_falta:  this.eja1.porcentagem_falta,



        notas_substituir_recup:  this.eja1.aRecuperacaoSubstitui.id,

        simulado:  this.eja1.simulado,
        atividades:  this.eja1.atividades,
        avaliacao:  this.eja1.avaliacao,
        media:  this.eja1.media,


        recuperacao_bimestral: (this.eja1.tipo_recuperacao.nome == 'Bimestral' ? 1 : 0),
        recuperacao_nota_b1:   this.eja1.recupercao_1b,
        recuperacao_nota_b2:   this.eja1.recupercao_2b,
        recuperacao_nota_b3:   0,
        recuperacao_nota_b4:   0,

        recuperacao_semestral: (this.eja1.tipo_recuperacao.nome == 'Semestral' ? 1 : 0),
        recuperacao_nota_s1:   this.eja1.primeiro_semestre,
        recuperacao_nota_s2:   0,

        recuperacao_final:  1,
        recuperacao_nota_final: this.eja1.recuperacao_final_nota,
      }

      if (tipo == 2) {
        novo.id = this.eja1.id;
        novo.configuracaos_id = this.eja1.configuracaos_id;

      }
      let data = '';

      if (tipo == 1)
        {data = await Configuracao.criarConfiguracaoPorSegmento(novo);}
      else
        {data = await Configuracao.editarConfiguracaoNotas(novo);}
    },
    async informacoesOkEja2(tipo) {
      const novo = {
        configuracaos_id:  this.configResult.id,
        tipo_segmento:  this.eja2.tipo_segmento,
        // tera_simulado:  this.eja2.tera_simulado,
        tera_simulado:  this.eja2.simulado_selec,
        tera_atividades:  this.eja2.atividade_selec,
        tera_avaliacao:  this.eja2.avaliacao_selec,
        tera_recuperacao:  this.eja2.recuperacao_selec,
        eja2:0,
        simulado_nome_exib:  this.eja2.simulado_nome_exib,
        atividades_nome_exib:  this.eja2.atividades_nome_exib,
        avaliacao_nome_exib:  this.eja2.avaliacao_nome_exib,
        porcentagem_falta:  this.eja2.porcentagem_falta,



        notas_substituir_recup:  this.eja2.aRecuperacaoSubstitui.id,

        simulado:  this.eja2.simulado,
        atividades:  this.eja2.atividades,
        avaliacao:  this.eja2.avaliacao,
        media:  this.eja2.media,


        recuperacao_bimestral: (this.eja2.tipo_recuperacao.nome == 'Bimestral' ? 1 : 0),
        recuperacao_nota_b1:   this.eja2.recupercao_1b,
        recuperacao_nota_b2:   this.eja2.recupercao_2b,
        recuperacao_nota_b3:   0,
        recuperacao_nota_b4:   0,

        recuperacao_semestral: (this.eja2.tipo_recuperacao.nome == 'Semestral' ? 1 : 0),
        recuperacao_nota_s1:   this.eja2.primeiro_semestre,
        recuperacao_nota_s2:   0,

        recuperacao_final:  1,
        recuperacao_nota_final: this.eja2.recuperacao_final_nota,
      }
      if (tipo == 2) {
        novo.id = this.eja2.id;
        novo.configuracaos_id = this.eja2.configuracaos_id;
      }
      let data = '';

      if (tipo == 1)
        {data = await Configuracao.criarConfiguracaoPorSegmento(novo);}
      else
        {data = await Configuracao.editarConfiguracaoNotas(novo);}


    },
    async informacoesOkEducacaoInfantil(tipo) {
        const novo = {
        calendario_letivos_id: this.configuracao.calendario_letivos_id,

        configuracaos_id:  this.configResult.id,
        tipo_segmento:  this.educ.tipo_segmento,
        tera_simulado:  0,
        tera_atividades: 0,
        tera_avaliacao: 0,
        tera_recuperacao:  0,
        eja2:0,
        simulado_nome_exib:  0,
        atividades_nome_exib:  0,
        avaliacao_nome_exib: 0,
        porcentagem_falta:  this.educ.porcentagem,



        notas_substituir_recup:  1,

        simulado: 0,
        atividades:  0,
        avaliacao:  0,
        media:  0,


        recuperacao_bimestral: 0,
        recuperacao_nota_b1:   0,
        recuperacao_nota_b2:   0,
        recuperacao_nota_b3:   0,
        recuperacao_nota_b4:   0,

        recuperacao_semestral: 0,
        recuperacao_nota_s1:   0,
        recuperacao_nota_s2:   0,

        recuperacao_final:  0,
        recuperacao_nota_final: 0
      }
      if (tipo == 2) {
        novo.id = this.educ.id;
        novo.configuracaos_id = this.educ.configuracaos_id;
      }
      let data = '';
      if (tipo == 1)
        {data = await Configuracao.criarConfiguracaoPorSegmento(novo);}
      else
        {data = await Configuracao.editarConfiguracaoNotas(novo);}


    },
    // verificarDatasConfi() {
    //   return (this.configuracao.b1_inicio != null && this.configuracao.b1_final != null && this.configuracao.b2_inicio != null && this.configuracao.b2_final != null && this.configuracao.b3_inicio != null && this.configuracao.b3_final != null && this.configuracao.b4_inicio != null && this.configuracao.b4_final != null);
    // },
    verificaCampos(){
      if (this.fund1.atividade_selec == null || this.fund1.simulado_selec == null || this.fund1.avaliacao_selec == null || this.fund1.recuperacao_selec == null) {
        this.$vaToast.init({
          message: 'Preencha os campos do fundamental 1',
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
        return false;
      }
      if (this.fund2.atividade_selec == null || this.fund2.simulado_selec == null || this.fund2.avaliacao_selec == null || this.fund2.recuperacao_selec == null) {
        this.$vaToast.init({
          message: 'Preencha os campos do fundamental 2',
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
        return false;
      }
      if (this.eja1.atividade_selec == null || this.eja1.simulado_selec == null || this.eja1.avaliacao_selec == null || this.eja1.recuperacao_selec == null) {
        this.$vaToast.init({
          message: 'Preencha os campos do eja 1',
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
        return false;
      }
      if (this.eja2.atividade_selec == null || this.eja2.simulado_selec == null || this.eja2.avaliacao_selec == null || this.eja2.recuperacao_selec == null) {
        this.$vaToast.init({
          message: 'Preencha os campos do eja 2',
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
        return false;
      }
      return true;
    },
    async enviarDados() {
      try {
        if (this.verificaCampos() == true) {
          if (!this.verificarSomaDasNotas()) {
            this.$vaToast.init({
              message: 'A soma das avaliações precisa ser igual a 100 para todos os segmentos!',
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'warning'
          });
          return false;
        }
        // else if (!this.verificarDatasConfi()) {
          //   this.$vaToast.init({
          //     message: 'Informe todas as datas de início e final!',
          //     iconClass: 'fa-star-o',
          //     position: 'top-right',
          //     duration: 3500,
          //     fullWidth: false,
          //     color: 'warning'
          //   });
          //   return false;
          // }
          else if (!this.verificarDatasPeriodos()) {
            this.$vaToast.init({
              message: 'A Configuração Geral não está configurada corretamente!',
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'warning'
            });
            return false;
          }
          this.carregarPagina = true;
          if (this.cadastrarOuEditar == 1)
          {await this.enviarConfiguracoes(this.cadastrarOuEditar);}
          await this.informacoesOkEducacaoInfantil(this.cadastrarOuEditar);
          await this.informacoesOkFundamentalUm(this.cadastrarOuEditar);
          await this.informacoesOkFundamentalDois(this.cadastrarOuEditar);
          await this.informacoesOkEja1(this.cadastrarOuEditar);
          await this.informacoesOkEja2(this.cadastrarOuEditar);

          this.carregarPagina = false;
          this.$vaToast.init({
            message: 'Enviado com sucesso!',
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'success'
          });
          this.$router.push({name: 'calendario'});

        }
      }
      catch(e) {
        this.carregarPagina = false;
        console.log('erro',e)
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }
        else{
          let a = [];
          a = e.response.data.erro;
          setTimeout(() => {
            this.$vaToast.init({
              message:a,
              iconClass: 'fa-star-o',
              position: 'bottom-left',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            });
          }, 500);
        }
      }
    },

  },
  async beforeMount() {
    this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));

    this.carregarPagina = true;
    //fundamental 1
    this.fund1.simulado_selec = this.fund1.tipos[1].tera
    this.fund1.avaliacao_selec = this.fund1.tipos[1].tera
    this.fund1.atividade_selec = this.fund1.tipos[1].tera
    this.fund1.recuperacao_selec = this.fund1.tipos[1].tera
    this.fund1.tipo_recuperacao = this.recuperacao[0]

    //fundamental 2
    this.fund2.simulado_selec = this.fund2.tipos[1].tera
    this.fund2.avaliacao_selec = this.fund2.tipos[1].tera
    this.fund2.atividade_selec = this.fund2.tipos[1].tera
    this.fund2.recuperacao_selec = this.fund2.tipos[1].tera
    this.fund2.tipo_recuperacao = this.recuperacao[0]

    //eja 1
    this.eja1.simulado_selec = this.eja1.tipos[1].tera
    this.eja1.avaliacao_selec = this.eja1.tipos[1].tera
    this.eja1.atividade_selec = this.eja1.tipos[1].tera
    this.eja1.recuperacao_selec = this.eja1.tipos[1].tera
    this.eja1.tipo_recuperacao = this.recuperacao[0]

    //eja 2
    this.eja2.simulado_selec = this.eja2.tipos[1].tera
    this.eja2.avaliacao_selec = this.eja2.tipos[1].tera
    this.eja2.atividade_selec = this.eja2.tipos[1].tera
    this.eja2.recuperacao_selec = this.eja2.tipos[1].tera
    this.eja2.tipo_recuperacao = this.recuperacao[0]

    const data = await Configuracao.buscarConfiguracao();
    //ainda nao existe configuracao
    if (!Object.keys(data.data).length) {
      this.ehParaEditar = false;
    }
    else {//ja existe configuracao
      this.ehParaEditar = true;
      this.cadastrarOuEditar = 2;
      this.autorizado = data.data.autorizado
      this.buscarDados(data.data);
    }

    this.carregarPagina = false;

  },
})

</script>

<style>
  #config_sistema .p-panel .p-panel-header{
    cursor:pointer;
  }
</style>